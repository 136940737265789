const Connections = {
    //-------------- development endpoints--------------------
    // url: 'http://localhost:3000',
    // api: 'http://localhost:8000/api/',
    // images: 'http://localhost:8000/api/images/',
    // itempictures: 'http://localhost:8000/api/itempictures/',

    //----------------testing api endpoints------------------

    api: 'https://test.sheketdelivery.com/service/api/',
    images: 'https://test.sheketdelivery.com/service/api/images/',
    itempictures: 'https://test.sheketdelivery.com/service/api/itempictures/',

    //-----------------backend api endpoints-----------------
    // api: 'https://service.sheketdelivery.com/api/',
    // images: 'https://service.sheketdelivery.com/api/images/',
    // itempictures: 'https://service.sheketdelivery.com/api/itempictures/',

    signin: 'signin',
    getcode: 'otp/register',
    verifyotp: 'otp/verify',
    forgotpassword: 'forgotpassword',
    resetpassword: 'resetpassword',
    refresh_token: 'refresh-token',

    main_cat: 'main-categories',
    sub_cat: 'sub-categories',
    item: 'item',
    priceupdate: 'price-updates',
    todayupdates: 'priceupdates/todays',

    //category api's
    addcategory: 'addcategory',
    viewcategory: 'viewcategory',
    editcategory: 'editcategory/',
    deletecategory: 'deletecategory/',

    //sub category api's
    addsubcategory: 'addsubcategory',
    subcategory: 'subcategory/',
    viewsubcategory: 'viewsubcategory',
    editsubcategory: 'editsubcategory/',
    deletesubcategory: 'deletesubcategory/',

    customers: 'customers',
    orders: 'orders',
    orderStatus: 'orders/status',
    orderItem: 'order-item'
};

export default Connections;
