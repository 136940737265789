import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 6,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));
const CartItems = () => {
    const items = useSelector((state) => state.cart.items);
    const navigate = useNavigate();
    return (
        <IconButton aria-label="cart" sx={{ marginRight: 2 }} onClick={() => navigate('/cart')}>
            <StyledBadge badgeContent={items.length} color="error">
                <ShoppingCartIcon color="grey" />
            </StyledBadge>
        </IconButton>
    );
};

export default CartItems;
